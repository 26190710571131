
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    RigSchedule: () => getComponent('schedule/RigSchedule'),
    ComponentDetails: () => getComponent('schedule/ComponentDetails'),
    AddSchedule: () => getComponent('schedule/AddSchedule'),
    Multiselect,
  },
})
export default class AddField extends Vue {
  addJobGroupSelected = ''

  fieldNameSelection = ''

  templateDetailsFieldIndexExpanded = []

  fieldSelection = ''

  addNewWorkspacePopupAddField = false

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  get fieldSelectionOptions() {
    const results: any[] = [];
    for (let y = 0; y < scheduleModule.columnMapping.length; y++) {
      if (scheduleModule.columnMapping[y].Enabled) {
        results.push(scheduleModule.columnMapping[y].ColumnName);
      }
    }
    return results;
  }

  get enabledWells() {
      return assetsModule.enabledWells;
  }

  setTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
    // @ts-ignore
    if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
      // @ts-ignore
    const index = this.templateDetailsFieldIndexExpanded.indexOf(indexPassed);
    if (index > -1) {
      this.templateDetailsFieldIndexExpanded.splice(index, 1);
    }
    } else {
      // @ts-ignore
      this.templateDetailsFieldIndexExpanded.push(indexPassed);
    }
  }

  templateDetails = [
    {
      type: 'Fields',
      values: [

      ],
    }, {
      type: 'Rules',
      values: [

      ],
    },
  ]

  applyNewWorkspacePopupAddField() {
  if (this.fieldSelection !== '' && this.fieldNameSelection !== '') {
    this.$emit('apply-new-workspace-popup-add-field', this.fieldSelection, this.fieldNameSelection);
  }
  this.fieldSelection = '';
  this.fieldNameSelection = '';
  }

  getTemplateDetailsFieldIndexExpanded(indexPassed) {
    // @ts-ignore
    if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
      return true;
    }
    return false;
  }

  closeAddNewWorkspacePopupAddField() {
    this.$emit('close-add-new-field-popup');
  }

  getColumnTypeIcon(columnType) {
    if (columnType === 'WELL') {
      return 'task';
    } if (columnType === 'USER') {
      return 'person';
    } if (columnType === 'VENDOR') {
      return 'engineering';
    } if (columnType === 'CHECKLIST') {
      return 'checklist';
    } if (columnType === 'FILE') {
      return 'picture_as_pdf';
    } if (columnType === 'TESTERS') {
      return 'quiz';
    } if (columnType === 'TASQ_DATE_RANGE') {
      return 'date_range';
    } if (columnType === 'COST') {
      return 'attach_money';
    } if (columnType === 'TAGS') {
      return 'tag';
    } if (columnType === 'TEXT') {
      return 'format_size';
    } if (columnType === 'DATE') {
      return 'calendar';
    } if (columnType === 'PROCEDURE') {
      return 'task';
    } if (columnType === 'EMAIL') {
      return 'email';
    } if (columnType === 'PHONE_NUMBER') {
      return 'phone';
    } if (columnType === 'STATUS') {
      return 'task_alt';
    }
      return 'task';
  }

  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    }));
  }
}

